import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import React from 'react';
import PaymentBox from './PaymentBox';

const ContactForm = () => {
  const location = useLocation();
  const userSelectedDate = location.state?.date;
  const sessDur = location.state?.sessionDuration;

  const formatDateTimeLocal = (date) => {
    if (!date) return ''; // Handle undefined or null date
    const dateTime = new Date(date);
    var tzoffset = (new Date()).getTimezoneOffset() * 60000; // offset in milliseconds
    var localISOTime = (new Date(dateTime - tzoffset)).toISOString().slice(0, -1);
    return localISOTime;
  };

  const [formData, setFormData] = useState({
    senderName: '',
    senderEmail: '',
    message: '',
    sessionDuration: sessDur,
    appointmentDateTime: formatDateTimeLocal(userSelectedDate),
    appointmentRequests: []
  });

  useEffect(() => {
    setFormData(prevState => ({
      ...prevState,
      sessionDuration: sessDur || '',
      appointmentDateTime: formatDateTimeLocal(userSelectedDate)
    }));
  }, [sessDur, userSelectedDate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  // const handlePaymentSuccess = (e) => {
  //   console.log('Payment handled.');
  // };

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = {
      name: formData.senderName,
      emailAddress: formData.senderEmail,
      message: formData.message,
      sessionDuration: Number(formData.sessionDuration),
      appointmentDateTime: new Date(formData.appointmentDateTime)
    };

    console.log('Payload:', payload);

    // Simulate sending data to BTCPay API
    console.log('Sending data to BTCPay API:', payload);
    alert('Request accepted. Generating payment screen...')

    // Reset form after submission
    resetUserInputs();
  };

  const resetUserInputs = () => {
    setFormData({
      senderName: '',
      senderEmail: '',
      message: '',
      sessionDuration: '',
      appointmentDateTime: '',
      appointmentRequests: []
    });
  };

  return (
    <div className="max-w-lg mx-auto my-10 p-6 bg-slate-900 shadow-md rounded-lg">
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="appointmentDateTime" className="block text-sm font-medium text-white">Selected Date & Time</label>
          <input
            type="datetime-local"
            name="appointmentDateTime"
            id="appointmentDateTime"
            value={formData.appointmentDateTime}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 text-gray-600 rounded-md shadow-sm focus:outline-none"
            readOnly
          />
        </div>
        <div>
          <label htmlFor="senderName" className="block text-sm font-medium text-white">Name</label>
          <input
            type="text"
            name="senderName"
            id="senderName"
            value={formData.senderName}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 text-black rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            required
          />
        </div>
        <div>
          <label htmlFor="senderEmail" className="block text-sm font-medium text-white">Email Address</label>
          <input
            type="email"
            name="senderEmail"
            id="senderEmail"
            value={formData.senderEmail}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 text-black rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            required
          />
        </div>
        <div>
          <label htmlFor="message" className="block text-sm font-medium text-white">
            Consultation Topic
            <div className="relative inline-block ml-2 group">
              <span className="text-sm text-white bg-gray-600 rounded-full px-2 py-1 cursor-pointer">i</span>
              <div className="absolute hidden group-hover:block w-64 p-2 bg-gray-600 text-white text-xs rounded-md shadow-lg -left-20 mt-1">
              Please provide a one-sentence overview of your inquiry so that the consultant can best prepare for your upcoming meeting.
              </div>
            </div>
          </label>
          <textarea
            name="message"
            id="message"
            value={formData.message}
            onChange={handleChange}
            rows="4"
            className="mt-1 block w-full px-3 py-2 border border-gray-300 text-black rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            required
          ></textarea>
        </div>
        <PaymentBox sessionDuration={formData.sessionDuration} customerDetails={{ name: formData.senderName, email: formData.senderEmail }} appointmentDateTime={formData.appointmentDateTime} />
      </form>
    </div>
  );
};

export default ContactForm;


