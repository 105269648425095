import React, { useEffect, useState, useCallback } from "react";

export const Event = ({ invoiceDetails }) => {
  const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
  const API_KEY = process.env.REACT_APP_GCAL_APIKEY;
  const DISCOVERY_DOC = "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest";
  const SCOPES = "https://www.googleapis.com/auth/calendar";

  const [isAuthorized, setIsAuthorized] = useState(false);
  const [eventAdded, setEventAdded] = useState(false);  // State to track if the event was added successfully

  // Memoizing initializeGapiClient to keep it stable across re-renders
  const initializeGapiClient = useCallback(async () => {
    try {
      await window.gapi.client.init({
        apiKey: API_KEY,
        discoveryDocs: [DISCOVERY_DOC],
      });
    } catch (error) {
      console.error("Failed to initialize GAPI client", error);
    }
  }, [API_KEY, DISCOVERY_DOC]); 

  useEffect(() => {
    function loadGapiAndGis() {
      window.gapi.load("client", initializeGapiClient);
      window.google.accounts.oauth2.initTokenClient({
        client_id: CLIENT_ID,
        scope: SCOPES,
        discovery_doc: DISCOVERY_DOC,
        callback: (response) => {
          if (response.error) {
            console.error("Authentication error", response);
            return;
          }
          setIsAuthorized(true);
        },
      });
    }
    loadGapiAndGis();
  }, [CLIENT_ID, SCOPES, DISCOVERY_DOC, initializeGapiClient]);

  function handleAuthClick() {
    const tokenClient = window.google.accounts.oauth2.initTokenClient({
      client_id: CLIENT_ID,
      scope: SCOPES,
      discovery_doc: DISCOVERY_DOC,
      callback: (response) => {
        if (response.error) {
          console.error("Authentication error", response);
        } else {
          setIsAuthorized(true);
        }
      }
    });
    tokenClient.requestAccessToken({ prompt: 'consent' });
  }

  function handleSignoutClick() {
    window.google.accounts.oauth2.revoke(window.gapi.client.getToken()?.access_token, () => {
      setIsAuthorized(false);
      setEventAdded(false);  // Reset event added state
    });
  }

  function addEvent() {
    const unixTimeStamp = invoiceDetails.metadata.apptDate; // Your Unix timestamp
    const startTime = new Date(unixTimeStamp * 1000); // Create a Date object from the Unix timestamp
  
    // Calculate the end time by adding session duration to the start time
    const endTime = new Date(startTime.getTime()); // Copy startTime to a new Date object for endTime
    endTime.setMinutes(startTime.getMinutes() + Number(invoiceDetails.metadata.sessDur)); // Add session duration to minutes
  
    // Convert both times to ISO string format required by Google Calendar API
    const startTimeISO = startTime.toISOString();
    const endTimeISO = endTime.toISOString();

    // Need to change event organiser: https://developers.google.com/calendar/api/v3/reference/events/move
  
    const event = {
      summary: `Consultation with ${invoiceDetails.metadata.buyer.name}`,
      location: '800 Howard St., San Francisco, CA 94103',
      description: 'Please use the corresponding Google Meet details to join this event. Refunds will not be issued if cancellation occurs after 24 hours. For any inquiries please email xnnanti@gmail.com with the subject line "BTCBooker Inquiry."',
      start: {
        dateTime: startTimeISO ,
        timeZone: 'Asia/Tokyo',
      },
      end: {
        dateTime: endTimeISO,
        timeZone: 'Asia/Tokyo',
      },
      attendees: [
        { email: 'xnnanti@gmail.com', organizer:true },
        { email: `${invoiceDetails.metadata.buyer.email}` },
      ],
      reminders: {
        useDefault: false,
        overrides: [
          { method: 'email', minutes: 1440 }, // 24 * 60
          { method: 'popup', minutes: 10 },
        ],
      },
      recurrence: [
        'RRULE:FREQ=DAILY;COUNT=1'
      ],
      conferenceData: {
        createRequest: {
          requestId: `${invoiceDetails.id}`}
      }
    };

    const request = window.gapi.client.calendar.events.insert({
      calendarId: "primary",
      resource: event,
      sendUpdates: "all",
      conferenceDataVersion:1
    });

    request.execute((response) => {
      console.log("Response received:", response);
      if (response.error) {
        console.error("Failed to add event:", response.error);
        setEventAdded(false);
        return;
      }
      setEventAdded(true);
      console.log("Event created successfully:", response.result.htmlLink);
    });
  }

  return (
    <div className="max-w-lg mx-auto my-10 p-6 rounded-lg">
      <div className="flex flex-col items-center">
        <button
          id="authorize_button"
          onClick={handleAuthClick}
          className={`py-2 px-4 text-white rounded transition duration-300 ease-in-out ${isAuthorized ? 'hidden' : 'block'} bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50 mb-4`}
        >
          Add Consultation to Google Calendar!
        </button>
        {!eventAdded && (
        <button
          id="signout_button"
          onClick={handleSignoutClick}
          className={`py-2 px-4 text-white rounded transition duration-300 ease-in-out ${isAuthorized ? 'block' : 'hidden'} bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-opacity-50 mb-4`}
        >
          Sign Out
        </button>
        )}
        {!eventAdded && (
        <button
          id="add_manual_event"
          onClick={addEvent}
          className={`py-2 px-4 text-white rounded transition duration-300 ease-in-out ${isAuthorized ? 'block' : 'hidden'} bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-50 mb-4`}
        >
          Add Event to My Calendar
        </button>
        )}
        {eventAdded && (
          <div className="mt-8 p-4 bg-gray-800 rounded-md shadow-md">
            <p className="text-sm text-white">
              Thank you for using BTCPay Scheduler. Your consultation invitation has been successfully scheduled.
              <br />
              Please join the consultation with xnnanti using the Google Meet details scheduled on your calendar.
            </p>
            <button
            className="mt-4 px-4 py-2 bg-red-500 text-white rounded-md"
            onClick={() => window.close()}
          >
            Exit BTCBooker
          </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Event;
