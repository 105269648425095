import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const API_KEY = process.env.REACT_APP_GCAL_APIKEY;
const DISCOVERY_DOC = "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest";

const Calendar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { error } = location.state || {};

    const [date, setDate] = useState(new Date());
    const [year, setYear] = useState(date.getFullYear()); //getYear gets the current, local month. 
    const [month, setMonth] = useState(date.getMonth()); //getMonth gets the current, local month. 
    const [calendarDays, setCalendarDays] = useState([]);

    // Date Selection 
    const [day, setDay] = useState(date.getDay());
    const [selectedDay, setSelectedDay] = useState(null);
    const [hours, setHours] = useState(date.getHours());
    const [minutes, setMinutes] = useState(date.getMinutes());
    const [appointmentDate, setAppointmentDate] = useState(null);
    const [sessionDuration, setSessionDuration] = useState(null)
    const [busyTimes, setBusyTimes] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    // const [selectedButtonKey, setSelectedButtonKey] = useState(null);

    useEffect(() => {
        setSelectedDate(null);
        setAppointmentDate(null);
        getEvents(year, month, selectedDay)
            .then(events => setBusyTimes(events)) // Assuming getEvents returns busy times directly
            .catch(console.error);
    }, [year, month, selectedDay, sessionDuration]); // If present, effect will only activate if the values in the list change.

    // useEffect(() => {
    //     setYear(date.getFullYear());
    //     setMonth(date.getMonth());
    // }, [date]);

    const handleChange = (dayValue) => {
        setSelectedDay(dayValue);
        displayAvailableTimes(dayValue);
    };

    const handleSessDur = (e) => {
        const { name, value } = e.target;
        const updatedValue = name === 'sessionDuration' ? parseInt(value, 10) : value;
        setSessionDuration(updatedValue)
      };

    const handleBooking = (selectedDate) => {
        navigate("/booking", { state: { date: selectedDate, sessionDuration: sessionDuration } });
    }
    
    // Array of month names
    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];


    // Update the calendar when the month or year changes or when user selects a different date.
    useEffect(() => {
        generateCalendar();
    }, [month, year, selectedDay]);

    const generateCalendar = () => {
        const dayOne = (new Date(year, month, 1).getDay() + 6) % 7; // Adjust so Monday is 0
        const lastDate = new Date(year, month + 1, 0).getDate();
        const monthLastDate = new Date(year, month, 0).getDate();
        const currentDayEnd = (new Date(year, month, lastDate).getDay() + 6) % 7; // Adjust so Monday is 0
        const today = new Date();
    
        let calendarHTML = [];
    
        // Dates from the previous month
        for (let i = dayOne; i > 0; i--) {
            calendarHTML.push({ day: monthLastDate - i + 1, className: "text-gray-500" });
        }
    
        // Dates of the current month
        for (let i = 1; i <= lastDate; i++) {
            const currentDate = new Date(year, month, i);
            let isToday = i === today.getDate() && month === today.getMonth() && year === today.getFullYear();
            const isPastDate = currentDate < today;
            let isSelected = selectedDay === i && currentDate >= today;

            let className = `${isToday ? "bg-pink-400 text-white rounded-md" : ""} ${isSelected ? "border border-black rounded-md" : ""} ${isPastDate ? "cursor-not-allowed text-gray-500" : ""}`;
            // TODO: this logic does not work
            if (isToday && isSelected) {
                className = "text-white rounded-md border-2 border-black";
            } else if (isSelected) {
                className = "border border-black rounded-md";
            }

            calendarHTML.push({
                day: i,
                value: i,
                className: className,
                onClick: !isPastDate || isToday ? () => handleChange(i) : null
            });
        }
        
        // Dates of the next month
        for (let i = 1; i < 7 - currentDayEnd; i++) {
            calendarHTML.push({ day: i, className: "text-gray-500" });
        }
    
        setCalendarDays(calendarHTML);
    };
    
    const changeMonth = (newMonth) => {
        const newDate = new Date(year, newMonth, 1);
        setYear(newDate.getFullYear());
        setMonth(newDate.getMonth());
        setSelectedDay(null); // Reset selected date to null
    };
    
// TODO: when user navigates to previously selected date the previously selected appt Time is still green
    const settingSelectedDate = (selectedDay, selectedHours, selectedMinutes) => {
        let selectedDate = new Date(year, month, selectedDay, selectedHours, selectedMinutes);
        setYear(selectedDate.getFullYear());
        setMonth(selectedDate.getMonth());
        setDay(selectedDate.getDay());
        setHours(selectedDate.getHours());
        setMinutes(selectedDate.getMinutes());
        setAppointmentDate(<button 
            value={selectedDate} 
            onClick={() => handleBooking(selectedDate)} 
            className="bg-green-600 hover:bg-green-700 text-black font-medium rounded-md py-2 px-4 mt-6"> Next </button>)

    };
    
    const initializeGapiClient = useCallback(async () => {
        try {
          await window.gapi.client.init({
            apiKey: API_KEY,
            discoveryDocs: [DISCOVERY_DOC],
          });
        } catch (error) {
          console.error("Failed to initialize GAPI client", error);
        }
      }, []);

      // TODO (later): when sessDur changes, calendar should not have to reload the enitre calendar from gapi.
      async function getEvents(year, month, selDay) {
        await window.gapi.load("client", initializeGapiClient);
        await window.gapi.client.load('calendar', 'v3');
    
        const timeMin = new Date(year, month, selDay, 0o1, 0o0, 0o0).toISOString();
        const timeMax = new Date(year, month, selDay, 23, 59, 59).toISOString();
        const request = {
            items: [{ id: 'xnnanti@gmail.com' }],
            timeMin: timeMin,
            timeMax: timeMax,
        };
    
        const res = await window.gapi.client.calendar.freebusy.query(request);
        const busyPeriods = res.result.calendars['xnnanti@gmail.com'].busy;
    
        if (!busyPeriods || busyPeriods.length === 0) {
            console.log('No busy times found, the calendar is free!');
            return [];
        }
    
        // Process the busy times into half-hour slots using processBusyTimes function defined below
        const busySlots = processBusyTimes(busyPeriods, timeMin, timeMax, sessionDuration);
        return busySlots;
    }
    
    function processBusyTimes(busyPeriods, timeMin, timeMax, sessionDuration) {
        let busySlots = [];
        const start = new Date(timeMin);
        const end = new Date(timeMax);
    
        // Ensure the loop increments by 30 minutes
        for (let dt = new Date(start); dt <= end; dt.setMinutes(dt.getMinutes() + 30)) {
            const nextHalfHour = new Date(dt);
            nextHalfHour.setMinutes(dt.getMinutes() + sessionDuration);
    
            const isBusy = busyPeriods.some(busy => {
                const busyStart = new Date(busy.start);
                const busyEnd = new Date(busy.end);
                // double checking
                return (busyStart < nextHalfHour && busyEnd > dt);
            });
    
            if (isBusy) {
                busySlots.push(dt.toISOString());
            }
        }
    
        return busySlots;
    }

    const [selectedButtonKey, setSelectedButtonKey] = useState(null);

    const displayAvailableTimes = (selectedDay) => {
        const intervals = [];
    
        // Create a set of busy time slots for quick lookup
        const busyTimeSlots = new Set(
            busyTimes.map(time => {
                const date = new Date(time);
                const hours = date.getHours();
                const minutes = date.getMinutes();
                return `${selectedDay}-${hours}:${minutes === 0 ? '00' : '30'}`;
            })
        );
    
        let h = 9;
        while (h < 17) {
            const timeFormatted = `${h % 12 === 0 ? 12 : h % 12}:00 ${h < 12 ? 'AM' : 'PM'}`;
            const hourKey = `${selectedDay}-${h}:00`;  // Key for the full hour button
    
            const createClickHandler = (hour, minutes, key) => () => {  
                setSelectedButtonKey(key);  // Update the selected button key state
                settingSelectedDate(selectedDay, hour, minutes);  // Additional logic can be placed here if necessary
            };
    
            const isBusy = busyTimeSlots.has(hourKey);
            const buttonClassName = isBusy ? "bg-gray-400 cursor-not-allowed" : selectedButtonKey === hourKey ? "bg-green-700 hover:bg-green-700" : "bg-blue-500 hover:bg-green-700";
            intervals.push(
                <button 
                    key={hourKey} 
                    value={h} 
                    onClick={!isBusy ? createClickHandler(h, 0, hourKey) : null} 
                    className={`${buttonClassName} text-white font-bold py-2 px-4 rounded shadow m-1`}
                    disabled={isBusy}
                >
                    {timeFormatted}
                </button>
            );
    
            const halfHourFormatted = `${h % 12 === 0 ? 12 : h % 12}:30 ${h < 12 ? 'AM' : 'PM'}`;
            const halfHourKey = `${selectedDay}-${h}:30`;  // Key for the half-hour button
            const isHalfHourBusy = busyTimeSlots.has(halfHourKey);
            const halfHourButtonClassName = isHalfHourBusy ? "bg-gray-400 cursor-not-allowed" : selectedButtonKey === halfHourKey ? "bg-green-700 hover:bg-green-700" : "bg-blue-500 hover:bg-green-700";
            intervals.push(
                <button 
                    key={halfHourKey} 
                    value={h} 
                    onClick={!isHalfHourBusy ? createClickHandler(h, 30, halfHourKey) : null} 
                    className={`${halfHourButtonClassName} text-white font-bold py-2 px-4 rounded shadow m-1`}
                    disabled={isHalfHourBusy}
                >
                    {halfHourFormatted}
                </button>
            );
    
            h++;
        }
    
        return intervals;
    };
    
      
    return (
        <div className='px-3 py-2 mt-1 mb-10'>
        {error && (
            <div className="bg-red-500 text-white p-4 rounded mb-4">
              {error}
            </div>
          )}

        <div className="p-4 rounded-md shadow-md flex flex-col items-center">
        <label htmlFor="sessionDuration" className="block text-sm font-medium text-white mb-2">Session Duration</label>
        <select
          name="sessionDuration"
          id="sessionDuration"
          onChange={handleSessDur}
          className="block w-50 px-3 py-2 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
        >
          <option value="30">Half Hour - US$0.5</option>
          <option value="60">Full Hour - US$1.00</option>
        </select>
      </div>
      
        <div className="mt-10 mb-10 block  bg-gradient-to-b from-[#d9d6d5] to-[#0e944b] w-full h-[400px]">
        <div className='w-2/3 mx-auto p-12'>
            <div className="flex justify-between items-center bg-white py-2 px-4 rounded-t-xl">
                <svg onClick={() => changeMonth(month - 1)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 rounded-full bg-gray-200 p-2.5 m-2.5">
                <path fillRule="evenodd" d="M7.72 12.53a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 1 1 1.06 1.06L9.31 12l6.97 6.97a.75.75 0 1 1-1.06 1.06l-7.5-7.5Z" clipRule="evenodd" />
                </svg> 
                <span className='text-2xl'>{months[month]} {year}</span>
                <svg onClick={() => changeMonth(month + 1)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 rounded-full bg-gray-200 p-2.5 m-2.5">
                <path fillRule="evenodd" d="M16.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 0 1-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 0 1 1.06-1.06l7.5 7.5Z" clipRule="evenodd" />
                </svg>
            </div>
            <div className="grid grid-cols-7 text-center py-2 bg-white">
                <div>Mon</div>
                <div>Tue</div>
                <div>Wed</div>
                <div>Thu</div>
                <div>Fri</div>
                <div>Sat</div>
                <div>Sun</div>
            </div>
            <ul className="grid grid-cols-7 py-3 text-center bg-white rounded-b-xl">
                {calendarDays.map((item, index) => (
                    <li key={index} className={`py-2 cursor-pointer ${item.className}`} onClick={item.onClick}>
                        {item.day}
                    </li>
                ))}
            </ul>
        </div>
    </div>
        {selectedDay && (
            <> 
            <div className='block p-4 mt-10'>
            
                <h2 className='text-center text-xl font-bold text-white pb-8'>Available Times</h2>
                <div className="grid grid-cols-4 gap-4">
                    {displayAvailableTimes(selectedDay)}
                </div>
                {appointmentDate}
            </div>
            </>
        )}
    </div>
    );
};

export default Calendar;
