import React from 'react';
import { useNavigate } from 'react-router-dom';

//TODO: if payment is cancelled (or user exits out of PaymentBox), go to a new cancellation screen (or take user back to ContactForm.)

const btcpayServerUrl = "https://testnet.demo.btcpayserver.org";
const storeId = process.env.REACT_APP_STORE_ID;
const apiKey = process.env.REACT_APP_CRINV_MODSTO_APIKEY;

const headers = {
  'Content-Type': 'application/json',
  'Authorization': 'token ' + apiKey
}

const apiEndpoint = '/api/v1/stores/' + storeId + '/invoices';

const PaymentBox = ({ sessionDuration, customerDetails, appointmentDateTime}) => {
  
  const navigate = useNavigate();
  const amount = sessionDuration === 60 ? 1 : 0.5;
  const handlePayment = async () => {
    try {
      const response = await fetch(btcpayServerUrl + apiEndpoint, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          amount,
          currency: 'USD', // sometimes may be issues with currency conversion, currently using kraken
          
          metadata: {
            orderId: `appointment_${new Date().toISOString()}`,
            itemDesc: `Consultation for ${sessionDuration} minutes on ${appointmentDateTime}`,
            sessDur: sessionDuration,
            apptDate: appointmentDateTime, //customisable invoice metadata
            buyer: {
              name: customerDetails.name,
              email: customerDetails.email,
            }
          },
          checkout: {
          }
        })
      });

      if (!response.ok) {
        const errorData = await response.json();  // Assuming the server sends JSON-formatted error messages
        console.error('Error creating invoice:', errorData);
        throw new Error(`API error: ${errorData.error} - ${errorData.message}`);
      }

      const data = await response.json();
      let invoice_paid = false;

      window.btcpay.showInvoice(data.id);

      window.btcpay.onModalReceiveMessage(function (event) {
        if (isObject(event.data)) {
          if (event.data.status) {
            switch (event.data.status) {
              case 'complete':
              case 'paid':
                invoice_paid = true;
                navigate('/processing', { state: { invoice: data } });
                break;
              case 'expired':
                window.btcpay.hideFrame();
                navigate('/', { state: { error: 'Payment session expired. Please try again.' } });
                break;
              default:
                break;
            }
          }
        } else {
          if (event.data === 'close') {
            if (invoice_paid) {
              navigate('/processing', { state: { invoice: data } });
            } else {
              navigate('/', { state: { error: 'Payment was cancelled. Please try again.' } });
            }
          }
        }
      });

    } catch (error) {
      console.error('Error creating invoice:', error);
      alert('Failed to initiate payment process.');
    }
  };

  const isObject = obj => {
    return Object.prototype.toString.call(obj) === '[object Object]';
  }
    
    return (
      <button
      onClick={handlePayment}
      className="bg-green-600 hover:bg-green-700 text-black font-medium rounded-md py-2 px-4 mt-6"
    >
      Proceed to Payment of ${amount}
    </button>
    
    );
  };

export default PaymentBox;