import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Event } from './Event.js';

const btcpayServerUrl = "https://testnet.demo.btcpayserver.org";
const storeId = process.env.REACT_APP_STORE_ID;
const apiKey = process.env.REACT_APP_CRINV_MODSTO_APIKEY;

const apiInvoiceEndpoint = '/api/v1/stores/' + storeId + '/invoices/';
//  

const headers = {
  'Content-Type': 'application/json',
  'Authorization': 'token ' + apiKey
};

const Processing = () => {
    const location = useLocation();
    const invoice = location.state?.invoice;
    const [invoiceDetails, setInvoiceDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!invoice) {
          setError('No invoice data available');
          setLoading(false);
          return;
        }

    const fetchInvoiceDetails = async () => {
    try {
        const response = await fetch(btcpayServerUrl + apiInvoiceEndpoint + invoice.id, {
            headers: headers,
            method: 'GET',
        });
        if (!response.ok) throw new Error('Network response was not ok');

        const data = await response.json();
        setInvoiceDetails(data);
        } catch (error) {
            console.error('Error retrieving invoice ID:', error);
            alert('Failed to process payment. Please contact mail@address.com');
        } finally {
                setLoading(false);
        }
    };

    fetchInvoiceDetails();

    const interval = setInterval(() => {
      fetchInvoiceDetails();
    }, 2000); // Poll every 2 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, [invoice]);

    if (loading) return <h2 className="text-center text-lg mt-4 animate-pulse">Loading...</h2>;

    if (error) return <h2 className="text-center text-lg mt-4">{error}</h2>;



    // const sendSuccessEmail = async ()  => { 
    //   try {
    //     const response = await fetch(btcpayServerUrl + apiEmailEndpoint, {
    //         mode: 'no-cors',
    //         headers: headers,
    //         method: 'POST',
    //         body: JSON.stringify({
    //           email: `${invoiceDetails.metadata.buyer.email}`,
    //           subject: `Appointment Details with *insertnamehere*`,
    //           body: `Thank you for using the open source scheduler. ${invoiceDetails.metadata.itemDesc} has been successfully created.  
    //           Please click here to add the meeting to your Google Calendar.
    //           If you need to cancel/reschedule, please contact x@y.com.`
    //         })
    //     });
    //     if (!response.ok) throw new Error('Network response was not ok');
    //     } catch (error) {
    //         console.error('Error sending email', error);
    //         alert('Failed to send confirmation email. Please contact x@y.com');
    //     }
    // }

    const isSettled = invoiceDetails.status === 'Settled';
    const statusButtonClass = isSettled
        ? 'bg-green-500 text-white px-4 py-2 rounded-md'
        : 'bg-orange-500 text-white px-4 py-2 rounded-md';
    
    const statusTextClass = isSettled
        ? 'text-green-500 text-2xl font-semibold text-center mb-2'
        : 'text-2xl text-orange-500 font-semibold text-center mb-2 animate-bounce';
    
        // if (isSettled) {
        //     try {
        //       sendSuccessEmail();
        //         console.log('Email sent successfully!');
        //     } catch (error) {
        //         console.error('Failed to send confirmation email:', error);
        //     }
        // }


  return (
    <div className="max-w-lg mx-auto my-10 p-6 bg-slate-900 shadow-md rounded-lg">
    <h2 className={statusTextClass}>{isSettled ? 'Processed' : 'Processing Payment...'}</h2>
      <h3 className="text-center mb-4 text-white">Please do not close this browser window.</h3>
      <div className="space-y-2">
        <p className="text-sm text-white">
          <strong>Invoice ID:</strong> {invoiceDetails.id}
        </p>
        <p className="text-sm text-white">
          <strong>Status:</strong>
          <span className={`ml-2 ${statusButtonClass}`}>
            {invoiceDetails.status === 'Settled' ? 'Settled' : 'Processing'}
          </span>
        </p>
        <p className="text-sm text-white">
          <strong>Order ID:</strong> {invoiceDetails.metadata.orderId}
        </p>
        <p className="text-sm text-white">
          <strong>Description:</strong> {invoiceDetails.metadata.itemDesc}
        </p>
        <p className="text-sm text-white">
          <strong>Buyer Name:</strong> {invoiceDetails.metadata.buyer.name}
        </p>
        <p className="text-sm text-white">
          <strong>Buyer Email:</strong> {invoiceDetails.metadata.buyer.email}
        </p>
      </div>
      <Event invoiceDetails={invoiceDetails}/>
    </div>
  );
};

export default Processing;
