import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Calendar from './Calendar';
import Processing from './Processing';
import ContactForm from './ContactForm';


function App() {
  return (
    <Router>
    <div className="App">
    <header className="App-header text-white p-6">
    <div className="container mx-auto flex justify-between items-center">
      <a className="text-3xl font-extrabold" href="/">BTCBooker</a>
      <p className="italic text-sm max-w-lg">
        Streamline your consultation booking process with BTCBooker. 
        Designed for convenience and efficiency, our scheduler allows you to effortlessly book POS consultation appointments with xnnanti@gmail.com.
      </p>
    </div>
  </header>
      <body className='bg-sxy-black min-h-screen'>
      <div className="max-w-4xl mx-auto p-4 shadow-lg rounded-lg">
      <Routes>
      <Route path="/" element={<Calendar />} />
      <Route path="/booking" element={<ContactForm />} />
      <Route path="/processing" element={<Processing />} />
      </Routes>
      </div>
      </body>
      <footer style={{ textAlign: "center" }} className='text-white justify-end bg-sxy-black pb-8'>
      BTCBooker @2024 Created by nikitakent.com
    </footer>
    </div>
    </Router>
  );
}

export default App;
